.climbing-type-selector {
    margin: 10px 0;
  }
  
  .climbing-type-selector label {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
  }
  
  .climbing-type-selector ul {
    list-style-type: none;
    padding: 0;
  }
  
  .climbing-type-selector li {
    margin: 5px 0;
    font-size: 0.9rem;
  }