.city-list {
    margin-top: 20px;
  }
  
  .city-list h2 {
    font-size: 1.5rem;
    color: #4a90e2;
    margin-bottom: 10px;
  }
  
  .city-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .city-list li {
    font-size: 1rem;
    padding: 5px 0;
    color: #333;
  }