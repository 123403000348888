.app-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align all items to the left */
    justify-content: flex-start; /* Align items to the top */
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f7f9fc;
    min-height: 100vh;
    gap: 15px; /* Add space between elements */
  }