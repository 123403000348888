.search-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .search-bar {
    width: 300px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
  }
  
  .search-button {
    padding: 10px 15px;
    font-size: 1rem;
    border: 1px solid #ddd;
    background-color: #4a90e2;
    color: white;
    cursor: pointer;
    border-radius: 0 4px 4px 0;
  }